.site-content-inner.page-timecard {
  .page-content {
    .ant-form {
      .employee-select {
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-right: 50px;
        }
      }
      .employee-navigation-buttons {
        position: absolute;
        right: 30px;
        top: 35px;
        .ant-btn {
          height: 20px;
          line-height: 0;
          padding: 0;
          width: 20px;
          &.disabled-button {
            i {
              color: #f2f3f8;
            }
          }
        }
      }
    }
  }
}