.site-content-inner.page-import-afd-file {
	.page-content {
		.ant-upload.ant-upload-select-picture-card,
		.ant-upload-list-picture-card-container {
			height:70px;
			display:block;
			width:100%;
		}
		.ant-upload-list-item-thumbnail {
			display:block !important;
		}
		.media-images-uploader {
			min-height:70px;
		}
	}
}
