body.page-print {
	font-size:12px;
	min-width:100%;
	overflow-x:auto;
	width:fit-content;

	.table-impressao {
		border:0;
		display:table !important;
		width:100%;
		page-break-after:auto;
		thead {
			display:table-header-group;
		}
		tfoot {
			display:table-footer-group;
		}
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th,
				> td {
					padding:0;
				}
			}
		}
	}

	// .page-break-after {
	// 	page-break-after:always;
	// }

	.table-impressao-interna {
		font-size:12px;
		width:100%;
		tr {
			td {
				line-height:1.3;
				padding:3px 0;
			}
		}
		.table-list-header {
			td {
				font-weight:700;
				color: white;
				background-color: #555;
			}
		}
		
	}

	.table-impressao-interna-color tr:nth-child(odd) {
		background-color: #fff;
	}
	
	.table-impressao-interna-color tr:nth-child(even) {
		background-color: #eee;
	}

	.table-list-row {
		border-bottom:1px solid #000;

		&:nth-child(odd) {
			background-color:#eee;
		}

		&:nth-child(even) {
			background-color:#fff;
		}
	}

	.p-20 {
		padding: 20px;
	}

	.hour-bank {
		header {
			.logo-container {
				padding: 0 10px;
				padding-top: 0px;
				display: flex;
				justify-content: space-between;

				.logo-title {
					div {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						line-height: 1.05;
					}
				}
			}
		}
	}	

	.time-card, .mirror-point {		
		header {
			.logo-container {
				padding: 0 20px;
				padding-top: 10px;
				display: flex;
				justify-content: space-between;

				.logo-title {
					div {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						line-height: 1.05;
					}
				}

				.logo-title > div:first-child {
					padding-top: 10px;
				}
			}
		}

		.working-hours {
			font-size: 9px;
			font-weight: 500;
			border-bottom: black 1px solid;
		}
	}

	.thead {
		background-color: #555555;
		color: #eee;
		font-size: 12px;
		font-weight: 700;
		padding: 5px;
	}

	.tbody {
		padding: 5px;
	}

	.logo-container {
		padding: 20px 0 0 0;
		text-align:center;
	}
	.text-container {
		padding:0 20px 20px;
		text-align:center;

		h1 {
			font-size:24px;
			font-weight:800;
			margin-bottom:8px;
		}

		p.subtitle {
			color:$primary-color-hover;
			font-size:16px;
			margin-bottom:5px;
		}
	}
	.row {
		box-shadow:none !important;
	}

	.user-info {
		margin-top: 5px;
		color: #555 !important;
	}

	.negrito {
		font-size: 9px;
		font-weight: bold;
	}

	.negrito-large {
		font-size: 12px;
		font-weight: bold;
	}

	.text-center {
		text-align: center;
	}
	
	.negrito-geral {
		font-weight: bold;
	}

	.row-section-flex {
		display: flex;
		flex-direction: row;
		gap: 5px;
	}

	@media print {
		* {
			-webkit-print-color-adjust:exact !important; /* Chrome, Safari, Edge */
			color-adjust:exact !important; /*Firefox*/
		}

		// .page-break {
		// 	page-break-before: always;
		// }
	}

	.ant-layout {
		background-color:#fff !important;
	}

}

// Cartão ponto
body.page-print-timecard {
	header {
		.info-container {
			padding: 0px 19px;

			> div {
				font-size: 9px;
			}

			.row-section {
				padding: 1px 0;
				border-bottom: #555555 1px solid;
			}
		}

		.logo-container > div:first-child {
			padding-top: 10px;
		}

		.info-container-large {
			padding: 5px 19px;

			> div {
				font-size: 13px;
			}

			.row-section {
				padding: 1px 0;
				border-bottom: #555555 1px solid;
			}

			.row-section-50 {
				padding: 1px 0;
				border-bottom: #555555 1px solid;
				width: 50%;
			}
		}

		.info-container-large > div:first-child {
			padding-top: 10px;
		}
	}

	.fontSize10 {
		font-size: 10px;
	}

	main {
		margin-bottom: 10px;
		padding-left: 18px;
		padding-right: 10px;
	}

	.page-break-after {
		page-break-after: always;
	}

	.entry-exit-cell {
		text-align: left;
		width: 45px;
		font-size: 8px;

		.cell-entry-exit-data {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 45px;
		}
	}

	.first-column-cell {
		text-align: left;
		width: 110px;
		font-size: 8px;
	}

	.last-column-cell {
		width: auto;
		font-size: 8px;
		text-align: left;
		margin-left: 5px;
	}

	.default-cell {
		text-align: center;
		width: 45px;
		font-size: 8px;
	}

	.last-column-content-center {
		margin-left: 10px;
	}
}

//Daily point
body.page-print-daily {
	.cell-default {
		font-size: 10px;
		text-align: center;
		margin-left: 5px;
		margin-right: 5px;
		width: 45px;
	}

	.cell-first-column {
		min-width: max-content;
		white-space: nowrap;
		text-align: left;
	}

	.cell-special-title {
		line-height: 1.05;
		text-align: center;
		width: 40px;
	}

	.cell-special-title-last {
		line-height: 1.05;
		text-align: left;
		width: auto;
	}

	.cell-last-column {
		width: auto;
		text-align: left;
	}

	.cell-entry-exit {
		text-align: left;

		.cell-entry-exit-data {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 45px;
		}
	}
}

.group-name {
	font-size: 16px;
	padding: 10px;
}

.groupby{
	font-size: 16px;
	margin-left: 10px;
	margin-top: 15px;
}

.groupby-table {
	margin-top: 5px;
	padding: 0 10px;
}

.mt-20px {
	margin-top: 20px;
}

.ml-10px {
	margin-left: 10px;
}

.ml-20px {
	margin-left: 20px;
}

.mr-20px {
	margin-right: 20px;
}
.mt-2rem {
	margin-top: 2rem;
}

.mt-3rem {
	margin-top: 3rem;
}

.mt-5rem {
	margin-top: 5rem;
}

.m-1rem {
	margin: 1rem;
}

.table-container {
	// overflow-x: auto;
	overflow-x: hidden;
}

.hours-table {
	border-collapse: collapse;
	width: 100%;
	white-space: nowrap;
}

.hours-table-large {
	border-collapse: collapse;
	width: 100%;
	white-space: nowrap;
}

.hours-table th, .hours-table td {
	text-align: left;
	font-size: 6px;
	white-space: normal;
}

.hours-table-large th, .hours-table-large td {
	text-align: left;
	font-size: 10px;
	white-space: normal;
}

.responsive-table {
	border-collapse: collapse;
	width: 100%;
	white-space: nowrap;
}

.responsive-table th, .responsive-table td {
	text-align: left;
	white-space: normal;
	
	&.large-table {
		font-size: 8px;
	}
}

.responsive-table th {
	background-color: #555555;
	color: #eee;
	font-size: 12px;
	font-weight: 500;
	padding: 2px;
}

.responsive-table tr {
	border-bottom: 1px solid #ddd;

	&:nth-child(odd) {
		background-color: #eee;
	}

	&:nth-child(even) {
		background-color: #fff;
	}
}

@media (max-width: 600px) {
	.responsive-table th, .responsive-table td {
		display: block;
		width: 100%;
		box-sizing: border-box;
		text-align: center;
	}
}

.content-subtitles {
	display: flex;
	flex-direction: row;
	font-size: 8px;
	margin-top: 5px;
	letter-spacing: 1px;
	gap: 10px;
	color: #787878;
}

.content-subtitles span:not(:first-child) {
	margin-left: 10px;
}

.assinaturas {
	text-align:center;
	text-transform:uppercase;
	> .ant-col {
		> div {
			border-top:1px solid #aaa;
			display:inline-block;
			padding-top:2px;
			width:300px;
		}
	}
}

// Espelho ponto
body.page-print-espelho-ponto {
	font-size: 10px;
	.table-impressao-interna {
		font-size: 10px;
		margin-bottom: 10px;
	}
	.header-dados {
		margin-bottom: 10px;

		.linha {
			border-bottom:1px solid #eee;
			display:flex;
			margin-bottom:2px;
			padding-bottom:2px;
			> div {
				width:100%;
			}
		}
	}

	.th-tratamentos {
		font-size: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100px;
	}

	.th-color {
		border-top: #555555 1px solid;
		line-height: 10px;
	}

	.row-section {
		padding: 3px;
		border-bottom: #555555 1px solid;
	}

	.page-break-after {
		page-break-after:always;
	}

	.cell-base {
		text-align: left;
	}
	
	.cell-first {
		width: 45px;
	}
	
	.cell-sequence {
		width: 35px;
	}
	
	.cell-sequence-end {
		width: 50px;
	}
	
	.cell-sequence-single {
		width: 20px;
	}
	
	.cell-penultimate {
		width: 100px;
	}
	
	.cell-last {
		width: auto;
	}	
}

.w50 {
	width: 50%;
}

.horarios-contratuais {
	.content-center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		margin-top: 10px;
		letter-spacing: 1px;
		color: #787878;

		.title {
			margin-bottom: 5px;
		}
		
		.jornadas {
			display: flex;
			flex-direction: column;
			margin-bottom: 5px;
		
			.jornada-row {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				gap: 50px;
				font-size: 10px;
		
				.codigo-horario {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					max-width: 500px;
		
					span {
						width: 100%;
					}
				}
		
				.entrada-saida {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					
					:last-child {
						margin-left: 5px;
					}

					span {
						font-size: 10px;
						margin-left: 10px;
					}
				}
			}
		}
	}
}

.assinaturas {
	text-align:center;
	text-transform:uppercase;
	> .ant-col {
		> div {
			border-top:1px solid #aaa;
			display:inline-block;
			padding-top:2px;
			width:300px;
		}
	}
}

.bold {
	font-weight: 700;
}

// Espelho ponto
body.table-impressao {
	font-size:10px;
	.table-impressao-interna {
		font-size:10px;
		margin-bottom:30px;
	}
	.header-dados {
		margin-bottom:30px;
		max-width:800px;
		.linha {
			border-bottom:1px solid #eee;
			display:flex;
			margin-bottom:2px;
			padding-bottom:2px;
			> div {
				width:100%;
			}
		}
	}
}

body.page-print-banco-de-horas {
	padding: 10px;
	.page-break {
		page-break-before: always;
	}
	
	.logo-container {
		position: relative;
	}

	.position-user {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		text-align: right;
		margin-right: 10px;
		> h4 {
			color: #555555;
		}
	}
	
	.row-section {
		padding: 5px 0;
		border-bottom: #aaa 1px solid;
	}

	p.subtitle {
		margin: 3px 0;
	}

}
